import React, { useState, useEffect } from "react";

interface AnyInterface{
  cart: any[];
  total: number;
  addToCart: (products: any) => void;
  increaseAmount: (id: any) => void;
  decreaseAmount: (id: any, amount: any) => void; 
  clearCart: () => void;
};

const defaultState = {
  cart: [],
  total: 0,
  paymentSent: false,
  setPaymentSent: () => {},
  addToCart: () => {},
  increaseAmount: () => {},
  decreaseAmount: () => {},
  clearCart: () => {},
};

const CartContext = React.createContext<AnyInterface>(defaultState);

const CartProvider: React.FC = ({ children }) => {
  const [cart, setCart] = useState<Array<object>>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const total = [...cart].reduce((total, {amount, price}: any) => {
      return (total += amount * price);
    }, 0);
    setTotal(parseFloat(total.toFixed(2)));
  }, [cart]);

  const increaseAmount = (id: any) => {
    const updatedCart = [...cart].map((item: any) => {
      return item.id === id ? { ...item, amount: item.amount + 1 } : item;
    });
    setCart(updatedCart);
  };

  const decreaseAmount = (id: any, amount: any) => {
    let updatedCart = [];
    if (amount === 1) {
      updatedCart = [...cart].filter((item: any) => item.id !== id);
    } else {
      updatedCart = [...cart].map((item: any) => {
        return item.id === id ? { ...item, amount: item.amount - 1 } : item;
      });
    }
    setCart(updatedCart);
  };

  const addToCart = (products: any) => {
    const { id, title, price, date, location, time } = products;

    const cartItem = [...cart].find((item: any) => item.id === id);
    if (cartItem) {
      increaseAmount(id);
    } else {
      const cartItems = [...cart, { id, title, price, date, location, time, amount: 1 }];
      setCart(cartItems);
    }
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider
      value={{ cart, total, addToCart, increaseAmount, decreaseAmount, clearCart }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };