import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
// import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import emailjs from '@emailjs/browser';
import { serviceID, templateID, userID } from './constant';
// import NcImage from "shared/NcImage/NcImage";
// import storeImage from "images/store.jpg";
import './PageContact.scss'

export interface PageContactProps {
  className?: string;
}

// const info = [
//   {
//     title: "Address:",
//     desc: "221B Baker Street, London",
//   },
//   {
//     title: "Hours of Operation:",
//     desc: "Tuesday - Thursdays, 10:00AM - 5:00PM"
//   }
// ];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  const [values, setValues] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [sent, setSent] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setValues((prevState) => ({ ...prevState, [name]: value }))
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    emailjs.sendForm(serviceID, templateID, e.target, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSent(true);
      }, (error) => {
        console.log('FAILED...', error);
      });

      e.target.reset();
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>M Tokyo Confectionery</title>
      </Helmet>

      {/* <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={storeImage}
      /> */}

      <div className="mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {"Contact Us"}
        </h2>
        <div className="container max-w-7xl mx-auto">
          {/* <div className="flex-shrink-0 grid grid-cols-2 gap-12 "> */}
          {/* <div className="flex flex-col lg:flex space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left"> */}

            {/* <div className="max-w-sm space-y-8"> */}
            {/* <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7"> */}
            {/* <div className="flex-grow"> */}
              {/* {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))} */}
              {/* <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div> */}
            {/* </div> */}
            <div className="w-screen max-w-full space-y-5 lg:space-y-7">
              {
                sent
                  ? 
                    <div
                      className={`nc-PageContactSuccessMessage px-4 max-w-3xl mx-auto pb-12 pt-14 sm:py-24 lg:pb-32`}
                      data-nc-id="PageContactSuccessMessage"
                    >
                      <h3 className="text-xl font-semibold">
                        {"Thank you for reaching out to us, we will get back to you within 2 business days."}
                      </h3>
                    </div>
                  :
                    <form 
                      className="grid grid-cols-1 gap-6" 
                      action="#" 
                      method="post"
                      onSubmit={handleSubmit}
                    >
                      <label className="block">
                        <Label>{"Full Name"}</Label>
      
                        <Input
                          placeholder={"Enter your full name"}
                          type="text"
                          className="mt-1"
                          name="name"
                          onChange={handleChange}
                        />
                      </label>
                      <label className="block">
                        <Label>{"Email Address"}</Label>
      
                        <Input
                          type="email"
                          placeholder="example@example.com"
                          className="mt-1"
                          name="email"
                          onChange={handleChange}
                        />
                      </label>
                      <label className="block">
                        <Label>{"Message"}</Label>
      
                        <Textarea 
                          className="mt-1" 
                          rows={6}
                          placeholder={"Enter your message"} 
                          name="message"
                          onChange={handleChange}
                        />
                      </label>
                      <div className="submitButtonWrapper">
                        <ButtonPrimary type="submit">{"Submit"}</ButtonPrimary>
                      </div>
                    </form>
              }
            </div>
          {/* </div> */}
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div>
  );
};

export default PageContact;
