import React from "react";
import MyRouter from "routers/index";
// AMPLIFY
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
// import { AuthContext } from "../src/context/authenticate";
Amplify.configure(awsExports);

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
