import React, { FC } from "react";
import OneButtonCommonLayout from "containers/PageAddListing1/OneButtonCommonLayout";
// import Heading from "components/Heading/Heading";
import './FaqPage.scss'
import { q2, q3, q4, q5, q6, q7, q8, a2, a3, a4, a5, a6, a7, a8 } from './constant';

export interface FaqProps {}

const FaqPage: FC<FaqProps> = () => {

  const qna = [
    {id: 1, question: q2, answer: a2},
    {id: 2, question: q3, answer: a3},
    {id: 3, question: q4, answer: a4},
    {id: 4, question: q5, answer: a5},
    {id: 5, question: q6, answer: a6},
    {id: 6, question: q7, answer: a7},
    {id: 7, question: q8, answer: a8}
  ];

  const instructionList = (source: any) => {
    return source.map((item: any, index: any) => {
      return <li key={index}>
                <p className="question">
                  {`${item.id}. ${item.question}`}
                </p>

                <p className="answer">
                  {item.answer}
                </p>
              </li>
    })
  };

  return (
    <OneButtonCommonLayout backtHref="/">
      {/* <Heading isCenter>
        {"FAQ"}
      </Heading> */}

      <h2 className="faqHeading text-4xl font-semibold">
        {"FAQ"}
      </h2>

      <ul className="instruction">
        {
          instructionList(qna)
        }
      </ul>
    </OneButtonCommonLayout>
  )
};

export default FaqPage;