import React, { FC } from "react";
import OneButtonCommonLayout from "containers/PageAddListing1/OneButtonCommonLayout";
// import Heading from "components/Heading/Heading";
import './PageWorkshop.scss';

export interface WorkshopProps {}

const WorkshopPage: FC<WorkshopProps> = () => {

  return (
    <OneButtonCommonLayout backtHref="/">
      {/* <Heading isCenter>
        {"Workshop"}
      </Heading> */}

      <h2 className="workshopHeading text-4xl font-semibold">
        {"Workshop"}
      </h2>

      <div style={{textAlign: 'center'}}>{"Coming soon..."}</div>
    </OneButtonCommonLayout>
  )
};

export default WorkshopPage;