/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processOrder = /* GraphQL */ `
  mutation ProcessOrder($input: ProcessOrderInput!) {
    processOrder(input: $input)
  }
`;
export const createDessert = /* GraphQL */ `
  mutation CreateDessert(
    $input: CreateDessertInput!
    $condition: ModelDessertConditionInput
  ) {
    createDessert(input: $input, condition: $condition) {
      id
      title
      description
      image
      author
      featured
      price
      orders {
        items {
          id
          dessert_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDessert = /* GraphQL */ `
  mutation DeleteDessert(
    $input: DeleteDessertInput!
    $condition: ModelDessertConditionInput
  ) {
    deleteDessert(input: $input, condition: $condition) {
      id
      title
      description
      image
      author
      featured
      price
      orders {
        items {
          id
          dessert_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDessertOrder = /* GraphQL */ `
  mutation CreateDessertOrder(
    $input: CreateDessertOrderInput!
    $condition: ModelDessertOrderConditionInput
  ) {
    createDessertOrder(input: $input, condition: $condition) {
      id
      dessert_id
      order_id
      dessert {
        id
        title
        description
        image
        author
        featured
        price
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      order {
        id
        user
        date
        total
        desserts {
          nextToken
        }
        createdAt
        updatedAt
        customer
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const deleteDessertOrder = /* GraphQL */ `
  mutation DeleteDessertOrder(
    $input: DeleteDessertOrderInput!
    $condition: ModelDessertOrderConditionInput
  ) {
    deleteDessertOrder(input: $input, condition: $condition) {
      id
      dessert_id
      order_id
      dessert {
        id
        title
        description
        image
        author
        featured
        price
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      order {
        id
        user
        date
        total
        desserts {
          nextToken
        }
        createdAt
        updatedAt
        customer
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      desserts {
        items {
          id
          dessert_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      desserts {
        items {
          id
          dessert_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      desserts {
        items {
          id
          dessert_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const updateDessert = /* GraphQL */ `
  mutation UpdateDessert(
    $input: UpdateDessertInput!
    $condition: ModelDessertConditionInput
  ) {
    updateDessert(input: $input, condition: $condition) {
      id
      title
      description
      image
      author
      featured
      price
      orders {
        items {
          id
          dessert_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDessertOrder = /* GraphQL */ `
  mutation UpdateDessertOrder(
    $input: UpdateDessertOrderInput!
    $condition: ModelDessertOrderConditionInput
  ) {
    updateDessertOrder(input: $input, condition: $condition) {
      id
      dessert_id
      order_id
      dessert {
        id
        title
        description
        image
        author
        featured
        price
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      order {
        id
        user
        date
        total
        desserts {
          nextToken
        }
        createdAt
        updatedAt
        customer
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
