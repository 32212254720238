import React, { useState } from "react";

interface googleInterface{
  products: any[];
  setProducts: (products: any) => void;
  checkout?: (orderDetails: any) => void;
};

const defaultState = {
  products: [],
  setProducts: () => {},
  checkout: () => {}
};

const GoogleContext = React.createContext<googleInterface>(defaultState);

const GoogleProvider: React.FC = ({ children }) => {

  const [products, setProducts] = useState<Array<object>>([]);

  return(
    <GoogleContext.Provider
      value={{ products, setProducts }}
    >
        {children}
    </GoogleContext.Provider>
  )
};

export { GoogleContext, GoogleProvider };