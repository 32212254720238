import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import VectorImg from "images/VectorHIW.svg";
import mango from "images/mango-pudding.jpeg";
import strawberry from "images/strawberry-pudding.jpeg";
import assorted from "images/assorted-pudding.jpeg";

export interface SectionHowItWorkProps {
  className?: string;
}

const PUDDING_DATA = [
  {
    id: 1,
    img: mango,
    title: "Book & relax",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
  {
    id: 2,
    img: strawberry,
    title: "Smart checklist",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
  {
    id: 3,
    img: assorted,
    title: "Save more",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <Heading isCenter desc="We consider ourselves a pudding enthusiast">
        Our Specialty
      </Heading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {PUDDING_DATA.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <NcImage
              containerClassName="mb-8 max-w-[200px] mx-auto"
              src={item.img}
              style={{ borderRadius: "5px", width: "200px" }}
            />
            {/* <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
