export const q1 = "When should I order my treats?";
export const q2 = "How much notice do you need?";
export const q3 = "Do you deliver?";
export const q4 = "Where do I pick up?";
export const q5 = "What is your payment policy for custom orders?";
export const q6 = "What is your cancellation policy?";
export const q7 = "Do you make gluten-free items?";
export const q8 = "Can your products be offered at outside event?";

export const a1 = "Please email us your inquiry through the Contact page.";

export const a2 = "Puddings and custom orders require minimum a weeks notice, but we will try our best to accommodate rush orders when possible. Please email us and We’ll get back to you within 24 business hours.";

export const a3 = "Currently pick-up is the only available option. Please select the pick up location, date and time upon carting the order.";

export const a4 = "Pick up location is in Vancouver West. The exact address will be given once your order is confirmed.";

export const a5 = "50% deposit is needed to reserve your order. All deposits are non-refundable. The balance is due 1 week prior to the event. Please contact us if you require further information.";

export const a6 = "We understand that plans can change and we will gladly assist to the best of our ability in making changes to your order date and time when possible. However, with cancellations, we reserves the right to retain the non-refundable deposit.";

export const a7 = "Unfortunately we are not offering gluten, vegan or dairy free option yet.";

export const a8 = "Our products generally require refrigeration. Placing the item outside during an event has the possibility of melting or deforming owing to the change in temperature. Please do not leave the item outside for too long especially under warm/hot weather.";