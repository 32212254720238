import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./Spinner.scss";

export interface SpinnerProps {
  className?: string;
};

const Spinner: FC<SpinnerProps> = ({ className = "" }) => {

  return(
    <div className={`nc-Spinner ${className}`}>
      <FontAwesomeIcon 
        icon={faSpinner} 
        className="fa-spinner fa-spin"
        size="9x"
      />
    </div>
  )
};

export default Spinner;