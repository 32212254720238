import React, { FC, Fragment,useContext, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
// import NcImage from "shared/NcImage/NcImage";
// import ModalPhotos from "containers/ListingDetailPage/ModalPhotos";
import { Dialog, Transition } from "@headlessui/react";
import { useParams, useHistory } from "react-router-dom";
import { CartContext } from "context/cart";
import { API } from "aws-amplify";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Spinner from "components/Spinner/Spinner";
import moment from "moment";
import { DayPickerSingleDateController } from "react-dates";
// import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
// import { DayPickerRangeController, DayPickerSingleDateController, FocusedInputShape } from "react-dates";
// import FulfillmentDatePicker from "components/FulfillmentDatePicker/FulfillmentDatePicker";
// import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import useWindowSize from "hooks/useWindowResize";
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Banner from "components/Banner/Banner";

export interface ListingProductPageProps {
  className?: string;
  isPreviewMode?: boolean;
};

const ProductDetailPage: FC<ListingProductPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  
  const { id } = useParams() as any;
  const history = useHistory();
  const momentDate = moment();
  // const { products } = useContext(GoogleContext);
  const { addToCart } = useContext(CartContext);

  // const [isOpen, setIsOpen] = useState(false);
  // const [openFocusIndex, setOpenFocusIndex] = useState(0);

  let options: any;
  let yaletownTime: any;
  let oakTime: any;
  let nikkeiTime: any;
  let blundellTime: any;
  let walmartTime: any;
  let langaraTime: any;
  let free1: any;
  let free2: any;

  if (id === "coffeeLoverDessertBox") {
    options = [
      { value: "" },
      { value: "Yaletown (1155 Pacific Blvd, in front of La Bottega Di Mangia)" },
      { value: "Oak & 49th (Detail address will be given upon confirmed order)" },
      { value: "Nikkei National Museum & Cultural Center (meeting point infront of Suzuya - April 30th Only))" },
      { value: "8100 No.2 Road, Blundell Center (Starbucks Parking Area) - Friday April 29th Only" },
      { value: "Richmond Walmart Lulu Island Liquor Store Parking - Saturday April 30th Only" },
      { value: "621 West 57th Avenue, Langara Gardens Parking - Saturday April 30th Only" },
      { value: "Free Delivery - Friday April 29th 1:00PM - 3:00PM (minimum order of 2 sets)" },
      { value: "Free Delivery - Saturday April 30th 11:00AM - 12:00PM (minimum order of 2 sets)" },
    ];

    nikkeiTime = [
      { value: "" },
      { value: "11:30AM" },
      { value: "11:45AM" },
      { value: "12:00PM" },
    ];
  
    yaletownTime = [
      { value: "" },
      { value: "12:00PM" },
      { value: "12:15PM" },
      { value: "12:30PM" },
    ];
  
    oakTime = [
      { value: "" },
      { value: "4:00PM" },
      { value: "4:30PM" },
      { value: "5:00PM" },
    ]; 

    blundellTime = [
      { value: "" },
      { value: "4:00PM" },
      { value: "4:15PM" },
    ];

    walmartTime = [
      { value: "" },
      { value: "12:00PM" },
      { value: "12:15PM" },
    ];

    langaraTime = [
      { value: "" },
      { value: "1:00PM" },
      { value: "1:15PM" },
    ];

    free1 = [
      { value: "" },
      { value: "1:00PM - 3:00PM" },
    ];

    free2 = [
      { value: "" },
      { value: "11:00AM - 12:00PM" },
    ];
  } else {
    options = [
      { value: "" },
      { value: "Yaletown (1155 Pacific Blvd, in front of La Bottega Di Mangia)" },
      { value: "Oak & 49th (Detail address will be given upon confirmed order)" },
    ];
  
    yaletownTime = [
      { value: "" },
      { value: "12:00PM" },
      { value: "12:15PM" },
      { value: "12:30PM" },
    ];
  
    oakTime = [
      { value: "" },
      { value: "4:00PM" },
      { value: "5:00PM" },
      { value: "6:00PM" },
      { value: "7:00PM" },
    ]; 
  }

  const [chosenDessert, setChosenDessert] = useState({
    title: '',
    description: '',
    description2: '',
    description3: '',
    price: '',
    quantity: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    image5: '',
    location: '',
    time: '',
    date: '',
  });
  const [soldOut, setSoldOut] = useState<any>(null);
  const [loaded, setLoaded] = useState<any>(undefined);

  // const [selectedDate, setSelectedDate] = useState<DateRage>({
  //   startDate: moment(),
  //   endDate: moment().add(7, "days"),
  // });

  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
  // const [dayBlock, setDayBlock] = useState<any>();
  // const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] = useState<FocusedInputShape>('startDate');
  const [isOpenModalPopUp, setIsOpenModalPopUp] = useState(false);
  const [minimumTime, setMinimumTime] = useState(true);

  const windowSize = useWindowSize();

  const isFocus = () => {
    return true;
  };

  const isDayBlocked = (day: any) => { 
    let blocked = false;

    if (id === "coffeeLoverDessertBox") {
      if (day.format('ddd') === 'Mon' 
        || day.format('ddd') === 'Tue'
        || day.format('ddd') === 'Wed'
        || day.format('ddd') === 'Thu'
        || day.format('ddd') === 'Sun'
      ) {
        blocked =  true
      }
    } else {
      if (day.format('ddd') === 'Mon' 
        || day.format('ddd') === 'Wed'
        || day.format('ddd') === 'Thu'
        || day.format('ddd') === 'Sat'
        || day.format('ddd') === 'Sun'
      ) {
        blocked =  true
      }
    } 

    return blocked;
  };

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  useEffect(() => {
    getOneDessert(id);
  }, []);

  useEffect(() => {
    setSoldOut(checker(chosenDessert));
  }, [chosenDessert, soldOut]);

  const getOneDessert = async (id: any) => {
    try {

      const res = await API.get('googleGetOneDessert', '/getOneDessert', {
        queryStringParameters: {
          rowIndex: id,
        }
      });

      const result = res.body.data;
      // console.log('Get One Res:', result);
      
      setChosenDessert(chosenDessert => ({
        ...chosenDessert,
        title: result.title,
        description: result.description,
        description2: result.description2,
        description3: result.description3,
        price: result.price,
        quantity: result.quantity,
        image1: result.image1,
        image2: result.image2,
        image3: result.image3,
        image4: result.image4,
        image5: result.image5
      }));

      setTimeout(() => {
        setLoaded(true);
      }, 500);

    } catch(err) {
      console.log('Get One Error:', err);
    }

  };

  let arr = [];

  for (let [key, value] of Object.entries(chosenDessert)) {
    if (key !== undefined && value !== undefined) {
      arr.push(value);
    }
  }

  let pattern = "https://drive.google.com";
  let filtered = arr.filter((str: any) => { return str.includes(pattern); })

  // const PHOTOS: string[] = [
  //   chosenDessert.image1,
  //   chosenDessert.image2,
  //   chosenDessert.image3,
  //   chosenDessert.image4,
  //   chosenDessert.image5
  // ];

  const checker = (input: any) => {
    let outOfStock;

    if (input.quantity === "0" || input.quantity === "") {
      outOfStock = true;
    } else if (input.quantity === "-1") {
      outOfStock = undefined;
    } else {
      outOfStock = false;
    };

    return outOfStock;
  };

  const finalPrice = (price: any) => {
    let total;
    total = price * 1.05
    return Math.round(total * 100)/100;
  };

  // const handleOpenModal = (index: number) => {
  //   setIsOpen(true);
  //   setOpenFocusIndex(index);
  // };

  // const handleCloseModal = () => setIsOpen(false);

  const handleLocationChange = (e: any) => {
    setChosenDessert(chosenDessert => ({
      ...chosenDessert,
      location: e.target.value,
    }));
  };

  const handleTimeChange = (e: any) => {
    setChosenDessert(chosenDessert => ({
      ...chosenDessert,
      time: e.target.value,
    }));
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);

    let justDate = date._d.toString().replace("12:00:00 GMT-0700 (Pacific Daylight Time)", "");
  
    setChosenDessert(chosenDessert => ({
      ...chosenDessert,
      date: justDate,
    }));
  };
  
  const closeModalPopUp = () =>  {
    setIsOpenModalPopUp(false);
  };

  const openModalPopUp = () => {
    setIsOpenModalPopUp(true);
  };

  const beforeCarting = () => {
    let currentDate = momentDate;
    let pickedDate = selectedDate;

    let dateDiff = pickedDate.diff(currentDate, 'days');

    if (chosenDessert.location === ""
        || chosenDessert.time === ""
        || chosenDessert.date === ""
      )
    { 
      setMinimumTime(true);
      openModalPopUp();
    } else if (dateDiff < 3) {
      setMinimumTime(false);
      openModalPopUp()
    } else {
      setMinimumTime(true);
      addToCart({ ...chosenDessert, id });
      history.push("/cart");
    };
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">{chosenDessert.title}</h2>
        <div className="w-100 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            {chosenDessert.description}
          </span>
        </div>
        {
          chosenDessert.description2 !== "" && (
            <div className="text-neutral-6000 dark:text-neutral-300">
              <span>
                {chosenDessert.description2}
              </span>
            </div>
          )
        }
        {
          chosenDessert.description3 !== "" && (
            <div className="text-neutral-6000 dark:text-neutral-300">
              <span>
                {chosenDessert.description3}
              </span>
            </div>
          )
        }
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">
          {"Terms & Conditions"}
        </h2>
        <div className="w-100 border-b border-neutral-200 dark:border-neutral-700" />
        {/* CONTENT */}
        <div>
          {/* <h4 className="text-lg font-semibold">Cancellation policy</h4> */}
          <span className="block text-neutral-500 dark:text-neutral-400">
            {"All products sold are final. Orders are pick-up only."}
          </span>

          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {"Please contact us if you are worried about potential allergic reactions."}
          </span>
        </div>

        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div> */}
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSection__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {`$${chosenDessert.price}`}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              {"per unit"}
            </span>
          </span>
          {/* <StartRating /> */}
        </div>

        {/* FORM */}
        {/* <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl "> */}
          {/* <FulfillmentDatePicker
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
            onChange={(date: any) => setSelectedDate(date)}
            numberOfMonths={1}
            fieldClassName="p-5"
            defaultValue={selectedDate}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
          /> */}
          {/* <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            fieldClassName="p-5"
            defaultValue={{
              guestAdults: 1,
              guestChildren: 2,
              guestInfants: 0,
            }}
          /> */}
        {/* </form> */}

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{"Subtotal:"}</span>
            <span>{`$${chosenDessert.price}`}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{"Sales Tax:"}</span>
            <span>5% Gst</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{"Total:"}</span>
            <span>{`$${finalPrice(chosenDessert.price)}`}</span>
          </div>
        </div>

        {/* SUBMIT */}
        <>
          {
            soldOut === true && (
              <ButtonSecondary>{"Sold Out"}</ButtonSecondary>
            )
          }
          {
            soldOut === false && (
              <ButtonPrimary
                onClick={() => {
                  beforeCarting();
                }}
              >
                {"Add to cart"}
              </ButtonPrimary>
            )
          }
          {
            renderPopUp()
          }
          {soldOut === undefined && (
            <ButtonSecondary>{"Please inquire"}</ButtonSecondary>
          )}
        </>
      </div>
    );
  };

  const renderSectionPickUpdate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        {
          id !== "coffeeLoverDessertBox" && (
            <div>
              <h2 className="text-2xl font-semibold">Fulfillment Dates</h2>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                All products are made by orders:
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                A minimum of 3 days from the date of order is required to cart the items
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Pick up dates are set twice a week:
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Tuesday & Friday
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Yaletown: 12:00PM ~ 12:30PM
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Oak & 49th West Area: after 4:00PM
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Orders will be unavailable from May 28th -  July 25th 2022
              </span>
            </div>
          )
        }
        
        {
          id === "coffeeLoverDessertBox" && (
            <div>
              <h2 className="text-2xl font-semibold">Fulfillment Dates</h2>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Pick up dates are set only on April 29th - 30th 2022:
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Friday & Saturday
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Yaletown: 12:00PM ~ 12:30PM (Friday - April 29th 2022)
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Nikkei National Museum & Cultural Center: 11:30AM ~ 12:00PM (Saturday - April 30th 2022)
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Oak & 49th West Area: 4:00PM ~ 5:00PM (Both Friday & Saturday - April 29th - 30th 2022)
              </span>

              <br/>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Little Flower Company pick up points:
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Blundell Center (Starbucks Parking Area), 8100 No.2 Road - Friday April 29th Only
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Richmond Walmart Lulu Island Liquor Store Parking - Saturday April 30th Only
              </span>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                621 West 57th Avenue, Langara Gardens Parking - Saturday April 30th Only
              </span>

              <br/>

              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                **FREE DELIVERY WILL ONLY BE AVAILABLE FOR RICHMOND LOCATION AT A MINIMUM ORDER OF 2 SETS** 
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Only available 1-3pm on Friday Arpil 29th , 11-12am on Saturday April 30th. Please choose Richmond location and contact Megu
              </span>
            </div>
          )
        }

        <div>
          <Label>Location:</Label>
          <Select className="mt-1.5" 
            onChange={handleLocationChange}
          >
            {
              options.map((point: any) => {
                return (
                  <option 
                    value={point.value}
                    key={point.value}
                  >
                    {point.value}
                  </option>
                )
              })
            }
          </Select>
        </div>
        {
          chosenDessert.location === "" && (
            <div>
              <Label>Time selection will be available once location is selected</Label>
            </div>
          )
        }
        {          
          chosenDessert.location === 'Nikkei National Museum & Cultural Center (meeting point infront of Suzuya - April 30th Only))' && (
            <div>
              <Label>Time:</Label>
              <Select className="mt-1.5" 
                onChange={handleTimeChange}
              >
                {
                  nikkeiTime.map((time: any) => {
                    return (
                      <option 
                        value={time.value}
                        key={time.value}
                      >
                        {time.value}
                      </option>
                    )
                  })
                }
              </Select>
            </div>
          )
        }
        {          
          chosenDessert.location === 'Yaletown (1155 Pacific Blvd, in front of La Bottega Di Mangia)' && (
            <div>
              <Label>Time:</Label>
              <Select className="mt-1.5" 
                onChange={handleTimeChange}
              >
                {
                  yaletownTime.map((time: any) => {
                    return (
                      <option 
                        value={time.value}
                        key={time.value}
                      >
                        {time.value}
                      </option>
                    )
                  })
                }
              </Select>
            </div>
          )
        }
        {
          chosenDessert.location === 'Oak & 49th (Detail address will be given upon confirmed order)' && (
            <div>
              <Label>Time:</Label>
              <Select className="mt-1.5" 
                onChange={handleTimeChange}
              >
                {
                  oakTime.map((time: any) => {
                    return (
                      <option 
                        value={time.value}
                        key={time.value}
                      >
                        {time.value}
                      </option>
                    )
                  })
                }
              </Select>
            </div>
          )
        }
        {
          chosenDessert.location === '8100 No.2 Road, Blundell Center (Starbucks Parking Area) - Friday April 29th Only' && (
            <div>
              <Label>Time:</Label>
              <Select className="mt-1.5" 
                onChange={handleTimeChange}
              >
                {
                  blundellTime.map((time: any) => {
                    return (
                      <option 
                        value={time.value}
                        key={time.value}
                      >
                        {time.value}
                      </option>
                    )
                  })
                }
              </Select>
            </div>
          )
        }
        {
          chosenDessert.location === 'Richmond Walmart Lulu Island Liquor Store Parking - Saturday April 30th Only' && (
            <div>
              <Label>Time:</Label>
              <Select className="mt-1.5" 
                onChange={handleTimeChange}
              >
                {
                  walmartTime.map((time: any) => {
                    return (
                      <option 
                        value={time.value}
                        key={time.value}
                      >
                        {time.value}
                      </option>
                    )
                  })
                }
              </Select>
            </div>
          )
        }
        {
          chosenDessert.location === '621 West 57th Avenue, Langara Gardens Parking - Saturday April 30th Only' && (
            <div>
              <Label>Time:</Label>
              <Select className="mt-1.5" 
                onChange={handleTimeChange}
              >
                {
                  langaraTime.map((time: any) => {
                    return (
                      <option 
                        value={time.value}
                        key={time.value}
                      >
                        {time.value}
                      </option>
                    )
                  })
                }
              </Select>
            </div>
          )
        }
        {
          chosenDessert.location === 'Free Delivery - Friday April 29th 1:00PM - 3:00PM (minimum order of 2 sets)' && (
            <div>
              <Label>Time:</Label>
              <Select className="mt-1.5" 
                onChange={handleTimeChange}
              >
                {
                  free1.map((time: any) => {
                    return (
                      <option 
                        value={time.value}
                        key={time.value}
                      >
                        {time.value}
                      </option>
                    )
                  })
                }
              </Select>
            </div>
          )
        }
        {
          chosenDessert.location === 'Free Delivery - Saturday April 30th 11:00AM - 12:00PM (minimum order of 2 sets)' && (
            <div>
              <Label>Time:</Label>
              <Select className="mt-1.5" 
                onChange={handleTimeChange}
              >
                {
                  free2.map((time: any) => {
                    return (
                      <option 
                        value={time.value}
                        key={time.value}
                      >
                        {time.value}
                      </option>
                    )
                  })
                }
              </Select>
            </div>
          )
        }
        <div className="w-100 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <div className="-mx-4 sm:mx-auto">
            <DayPickerSingleDateController
              date={selectedDate}
              onDateChange={(date) => handleDateChange(date)}
              onFocusChange={isFocus}
              focused
              initialVisibleMonth={null}
              numberOfMonths={windowSize.width < 1280 ? 1 : 2}
              daySize={getDaySize()}
              hideKeyboardShortcutsPanel
              isDayBlocked={(momentDate) => isDayBlocked(momentDate)}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPopUp = () => {
    return (
      <Transition appear show={isOpenModalPopUp} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalPopUp}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div 
                  className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full"
                  style={{height: '25%'}}
                >
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Missing Info
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalPopUp} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {/* {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-6 space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))} */}
                    <div 
                      className="flex items-center space-x-8"
                      style={{paddingTop: '3.5rem', paddingBottom: '3.5rem'}}
                    >
                      {
                        minimumTime 
                          ?
                            <h3
                              className={`text-2xl text-neutral-6000`}
                            >
                              {"You must select location, date and time before carting each product."}
                            </h3>
                          :
                            <h3
                              className={`text-2xl text-neutral-6000`}
                            >
                              {"Fulfillment date must be at least 3 days from now"}
                            </h3>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
      <div
        className={`nc-ProductDetailPage  ${className}`}
        data-nc-id="ProductDetailPage"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {
          loaded === undefined && (
            <Spinner className="pt-24 pb-24 lg:py-32" />
          )
        }
        
        {
          loaded === true && (
            <>
              {/* SINGLE HEADER */}

                <>
                  <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
                      {/* <NcImage
                        className="w-full rounded-xl"
                        containerClassName="container my-10 sm:my-12 "
                        src={PHOTOS[0]}
                      /> */}
                      <Banner imageArr={filtered} />
                    {/* <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2"> */}
                      {/* <div
                        className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                        onClick={() => handleOpenModal(0)}
                      >
                        <NcImage
                          containerClassName="absolute inset-0"
                          className="object-cover w-full h-full rounded-md sm:rounded-xl"
                          src={PHOTOS[0]}
                          prevImageHorizontal
                        />
                        <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                      </div> */}

                      {/* {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                        <div
                          key={index}
                          className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                            index >= 3 ? "hidden sm:block" : ""
                          }`}
                        >
                          <NcImage
                            containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                            className="object-cover w-full h-full rounded-md sm:rounded-xl "
                            src={item || ""}
                            prevImageHorizontal
                          />
                          <div
                            className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                            onClick={() => handleOpenModal(index + 1)}
                          />
                        </div>
                      ))} */}

                      {/* <div
                        className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                        onClick={() => handleOpenModal(0)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                          />
                        </svg>
                        <span className="ml-2 text-neutral-800 text-sm font-medium">
                          {"Show pictures"}
                        </span>
                      </div> */}
                    {/* </div> */}
                  </header>
                  {/* MODAL PHOTOS */}
                  {/* <ModalPhotos
                    imgs={PHOTOS}
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    initFocus={openFocusIndex}
                  /> */}
                </>

                {/* MAIn */}
                <main className="container mt-11 flex ">
                  {/* CONTENT */}
                  <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
                    {/* {renderSection1()} */}
                    {renderSection2()}
                    {/* {renderSection3()} */}
                    {/* {renderSection4()} */}
                    {renderSectionPickUpdate()}
                    {/* {renderSection5()} */}
                    {/* {renderSection6()} */}
                    {/* {renderSection7()} */}
                    {renderSection8()}
                  </div>

                  {/* SIDEBAR */}
                  <div className="hidden lg:block flex-grow">
                    <div className="sticky top-24">{renderSidebar()}</div>
                  </div>
                </main>

                {/* STICKY FOOTER MOBILE */}
                {!isPreviewMode && (
                  <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
                    <div className="container flex items-center justify-between">
                      <span className="text-2xl font-semibold">
                        {`$${chosenDessert.price}`}
                        <span 
                          className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400"
                        >
                          {"per unit + GST"}
                        </span>
                      </span>

                      <>
                        {
                          soldOut === true && (
                            <ButtonSecondary>{"Sold out"}</ButtonSecondary>
                          )
                        }
                        {
                          soldOut === false && (
                            <ButtonPrimary
                              onClick={() => {
                                beforeCarting();
                              }}
                            >
                              {"Add to cart"}
                            </ButtonPrimary>
                          )
                        }
                        {soldOut === undefined && (
                          <ButtonSecondary>{"Please Inquire"}</ButtonSecondary>
                        )}
                      </>
                    </div>
                  </div>
                )}
            </>
          )
        }
      </div>
  );
};

export default ProductDetailPage;