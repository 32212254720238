import React from "react";
import ReactDOM from "react-dom";
//
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleProvider } from "context/google";
import { CartProvider } from "./context/cart";

ReactDOM.render(
  <React.Suspense fallback={'loading'}>
    <GoogleProvider>
      <CartProvider>
        <App />
      </CartProvider>
    </GoogleProvider>
  </React.Suspense>
, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
