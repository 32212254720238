import React, { FC, useContext } from "react";
import CommonLayout from "../../containers/PageAddListing1/CommonLayout";
// import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { CartContext } from "context/cart";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";

export interface CartProps {}

const Cart: FC<CartProps> = () => {

  const { cart, total, increaseAmount, decreaseAmount } = useContext(CartContext);

  if (!cart.length) {
    return <CommonLayout
              backtHref="/products"
              nextHref="/"
            > 
              <>
                <h2 
                  className="text-2xl font-semibold flex justify-center"
                >
                  {"Your cart is empty"}
                </h2>
              </>
            </CommonLayout>
  };

  const finalPrice = (price: any) => {
    let total;
    total = price * 1.05
    return Math.round(total * 100)/100;
  };

  return (
    <CommonLayout
      backtHref="/products"
      nextHref="/checkout"
    >
      <>
        <h2 className="text-2xl font-semibold flex justify-center">{"My Cart"}</h2>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          {cart.map((item) => {
            return <div
                      className="nc-NcInputNumber flex items-center justify-between w-full"
                      data-nc-id="NcInputNumber"
                      key={item.id}
                    >
                      <div className="flex flex-col">
                        <span 
                          className="font-medium text-neutral-800 dark:text-neutral-200"
                        >
                          {item.title}
                        </span>

                        <span 
                          className="text-s text-neutral-500 dark:text-neutral-400 font-normal"
                        >
                          {`$${item.price} per unit`}
                        </span>
                      </div>
      
                      <div
                        className={`nc-NcInputNumber flex items-center justify-between w-28 ml-5`}
                      >
                        <button
                          className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                          type="button"
                          onClick={() => decreaseAmount(item.id, item.amount)}
                        >
                          <MinusIcon className="w-4 h-4" />
                        </button>

                        <span>{item.amount}</span>

                        <button
                          className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                          type="button"
                          onClick={() => increaseAmount(item.id)}
                        >
                          <PlusIcon className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
          })}

          <div className="flex justify-between">
            <h3 className="text-2xl font-semibold">
              Gst 5%
            </h3>
            <div></div>
            <h3 className="text-2xl font-semibold">
              {`Total: $ ${finalPrice(total)}`}
            </h3>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default Cart;