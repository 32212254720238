import React, { FC, Fragment, useState } from "react";
import { Tab } from "@headlessui/react";
// import { PencilAltIcon } from "@heroicons/react/outline";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
// import Textarea from "shared/Textarea/Textarea";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import NcImage from "shared/NcImage/NcImage";
// import StartRating from "components/StartRating/StartRating";
// import NcModal from "shared/NcModal/NcModal";
// import { Authenticator } from '@aws-amplify/ui-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from "components/CheckoutForm/CheckoutForm";
import OneButtonCommonLayout from "containers/PageAddListing1/OneButtonCommonLayout";
// import { CartContext } from "context/cart";
// import { useTranslation } from "react-i18next";
// import { AuthContext } from "context/authenticate";
import { publishableKey } from "./constant";

export interface CheckOutPageProps {
  className?: string;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {

  const stripePromise = loadStripe(publishableKey.prod);
  // const stripePromise = loadStripe(publishableKey.prod);

  // const { auth } = useContext(AuthContext);

  const [paymentSent, setPaymentSent] = useState<any>(undefined);
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: ''
  });

  const paymentTrigger = (input: any) => {
    if (input === "true") {
      setPaymentSent(true);
    } else {
      setPaymentSent(false);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setValues((prevState) => ({ ...prevState, [name]: value }))
  };

  // const renderSidebar = () => {
  //   return (
  //     <>
  //       { 
  //         cart.map((item)=> {
  //           return <div 
  //                     className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8"
  //                     key={item.id}
  //                   >
  //                     <div className="flex flex-col sm:flex-row sm:items-center">
  //                       <div className="flex-shrink-0 w-full sm:w-40">
  //                         <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
  //                           <NcImage src="https://images.pexels.com/photos/6373478/pexels-photo-6373478.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" />
  //                         </div>
  //                       </div>
  //                       <div className="py-5 sm:px-5 space-y-3">
  //                         <div>
  //                           {/* <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
  //                             Hotel room in Tokyo, Jappan
  //                           </span> */}
  //                           <span className="text-base font-medium mt-1 block">
  //                             {item.title}
  //                           </span>
  //                         </div>
  //                         {/* <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
  //                           2 beds · 2 baths
  //                         </span>
  //                         <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
  //                         <StartRating /> */}
  //                       </div>
  //                     </div>
  //                     <div className="flex flex-col space-y-4">
  //                       <h3 className="text-2xl font-semibold">Price detail</h3>
  //                       <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //                         <span>{`${item.price} x ${item.amount}`}</span>
  //                         <span>{`Subtotal: ${item.total}`}</span>
  //                       </div>
  //                       {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //                         <span>Service charge</span>
  //                         <span>$0</span>
  //                       </div> */}

  //                       <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
  //                       <div className="flex justify-between font-semibold">
  //                         <span>Total</span>
  //                         <span>$57</span>
  //                       </div>
  //                     </div>
  //                   </div>
  //         })
  //       }
  //     </>
  //   );
  // };

  // const renderMain = () => {
  //   return (
  //     <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
  //       <h2 className="text-3xl lg:text-4xl font-semibold">
  //         {"Check out"}
  //       </h2>
  //       <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* <div>
  //         <div>
  //           <h3 className="text-2xl font-semibold">Your trip</h3>
  //           <NcModal
  //             renderTrigger={(openModal) => (
  //               <span
  //                 onClick={() => openModal()}
  //                 className="block lg:hidden underline  mt-1 cursor-pointer"
  //               >
  //                 View booking details
  //               </span>
  //             )}
  //             renderContent={renderSidebar}
  //           />
  //         </div>
  //         <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
  //           <div className="flex-1 p-5 flex justify-between space-x-5">
  //             <div className="flex flex-col">
  //               <span className="text-sm text-neutral-400">Date</span>
  //               <span className="mt-1.5 text-lg font-semibold">
  //                 Aug 12 - 16, 2021
  //               </span>
  //             </div>
  //             <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" />
  //           </div>
  //           <div className="flex-1 p-5 flex justify-between space-x-5">
  //             <div className="flex flex-col">
  //               <span className="text-sm text-neutral-400">Guests</span>
  //               <span className="mt-1.5 text-lg font-semibold">3 Guests</span>
  //             </div>
  //             <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" />
  //           </div>
  //         </div>
  //       </div> */}

  //       <div>
  //         <h3 className="text-2xl font-semibold">{"CheckOutForm.pageSubTitle"}</h3>
  //         <div className="mt-6">
  //           <Tab.Group>
  //             <Tab.List className="flex">
  //               <Tab as={Fragment}>
  //                 {({ selected }) => (
  //                   <button
  //                     className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
  //                       selected
  //                         ? "bg-neutral-800 text-white"
  //                         : " text-neutral-6000 dark:text-neutral-400"
  //                     }`}
  //                   >
  //                     <span className="mr-2.5">
  //                       {"CheckOutForm.creditCard"}
  //                     </span>
  //                     <img className="w-8" src={visaPng} alt="" />
  //                     <img className="w-8" src={mastercardPng} alt="" />
  //                   </button>
  //                 )}
  //               </Tab>
  //               {/* <Tab as={Fragment}>
  //                 {({ selected }) => (
  //                   <button
  //                     className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
  //                       selected
  //                         ? "bg-neutral-800 text-white"
  //                         : "text-neutral-6000 dark:text-neutral-400"
  //                     }`}
  //                   >
  //                     Paypal
  //                   </button>
  //                 )}
  //               </Tab> */}
  //             </Tab.List>

  //             <div className="w-14 border-b border-neutral-200 my-5"></div>
  //             <Tab.Panels>
  //               <Tab.Panel className="space-y-5">
  //                 {/* <div className="space-y-1">
  //                   <Label>Card number </Label>
  //                   <Input defaultValue="111 112 222 999" />
  //                 </div>
  //                 <div className="space-y-1">
  //                   <Label>Card holder </Label>
  //                   <Input defaultValue="JOHN DOE" />
  //                 </div>
  //                 <div className="flex space-x-5  ">
  //                   <div className="flex-1 space-y-1">
  //                     <Label>Expiration date </Label>
  //                     <Input type="date" defaultValue="MM/YY" />
  //                   </div>
  //                   <div className="flex-1 space-y-1">
  //                     <Label>CVC </Label>
  //                     <Input />
  //                   </div>
  //                 </div> */}
  //                 {/* <div className="space-y-1">
  //                   <Label>Messager for author </Label>
  //                   <Textarea placeholder="..." />
  //                   <span className="text-sm text-neutral-500 block">
  //                     Write a few sentences about yourself.
  //                   </span>
  //                 </div> */}
  //                 <CheckoutForm paymentTrigger={paymentTrigger}/>
  //               </Tab.Panel>
  //               {/* <Tab.Panel className="space-y-5">
  //                 <div className="space-y-1">
  //                   <Label>Email </Label>
  //                   <Input type="email" defaultValue="example@gmail.com" />
  //                 </div>
  //                 <div className="space-y-1">
  //                   <Label>Password </Label>
  //                   <Input type="password" defaultValue="***" />
  //                 </div>
  //                 <div className="space-y-1">
  //                   <Label>Messager for author </Label>
  //                   <Textarea placeholder="..." />
  //                   <span className="text-sm text-neutral-500 block">
  //                     Write a few sentences about yourself.
  //                   </span>
  //                 </div>
  //                 <div className="pt-4">
  //                   <ButtonPrimary>Confirm and pay</ButtonPrimary>
  //                 </div>
  //               </Tab.Panel> */}
  //             </Tab.Panels>
  //           </Tab.Group>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderGuestMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          {"Confirm Payment"}
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        

        <div>
          <h3 className="text-2xl font-semibold">{"Pay with"}</h3>
          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-neutral-800 text-white"
                          : " text-neutral-6000 dark:text-neutral-400"
                      }`}
                    >
                      <span className="mr-2.5">
                        {"Credit Card"}
                      </span>
                      <img className="w-8" src={visaPng} alt="" />
                      <img className="w-8" src={mastercardPng} alt="" />
                    </button>
                  )}
                </Tab>
                {/* <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 text-white"
                          : "text-neutral-6000 dark:text-neutral-400"
                      }`}
                    >
                      Paypal
                    </button>
                  )}
                </Tab> */}
              </Tab.List>

              <div className="w-100 border-b border-neutral-200 my-5"></div>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>{"Full Name"}</Label>
                    <Input type="text" name="name" onChange={handleChange} placeholder={"Please enter your full name"} />
                  </div>
                  <div className="space-y-1">
                    <Label>{"Email Address"}</Label>
                    <Input type="email" name="email" onChange={handleChange} placeholder={"Please enter your email address"} />
                  </div>
                  <div className="space-y-1">
                    <Label>{"Phone Number"}</Label>
                    <Input name="phone" onChange={handleChange} placeholder={"Please enter your phone number"} />
                  </div>
                  {/* <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Expiration date </Label>
                      <Input type="date" defaultValue="MM/YY" />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>CVC </Label>
                      <Input />
                    </div>
                  </div> */}
                  {/* <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div> */}

                  {/* Actual Checkout Form */}

                  <CheckoutForm paymentTrigger={paymentTrigger} guestValues={values}/>

                </Tab.Panel>

                {/* <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" defaultValue="example@gmail.com" />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input type="password" defaultValue="***" />
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                  <div className="pt-4">
                    <ButtonPrimary>Confirm and pay</ButtonPrimary>
                  </div>
                </Tab.Panel> */}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <>
        {
          paymentSent === undefined && (
            // <Authenticator>
            //   {
            //     () => (
            //       <Elements stripe={stripePromise}>
            //         {/* <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row"> */}
            //         <main className="container mt-11 mb-24 lg:mb-32 flex justify-center lg:flex-row">
            //           <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
            //             {renderMain()}
            //           </div>
            //           {/* <div className="hidden lg:block flex-grow">{renderSidebar()}</div> */}
            //         </main>  
            //       </Elements> 
            //     )
            //   }
            // </Authenticator>

            <>
              {/* { */}
                {/* auth    */}
                  ?
                    {/* <Elements stripe={stripePromise}> */}
                      {/* <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row"> */}
                      {/* <main className="container mt-11 mb-24 lg:mb-32 flex justify-center lg:flex-row"> */}
                        {/* <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 "> */}
                          {/* {renderMain()} */}
                        {/* </div> */}
                        {/* <div className="hidden lg:block flex-grow">{renderSidebar()}</div> */}
                      {/* </main>   */}
                    {/* </Elements>  */}
                  {/* // : */}
                    {/* <Elements stripe={stripePromise}> */}
                      {/* <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row"> */}
                      {/* <main className="container mt-11 mb-24 lg:mb-32 flex justify-center lg:flex-row"> */}
                        {/* <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 "> */}
                          {/* {renderGuestMain()} */}
                        {/* </div> */}
                        {/* <div className="hidden lg:block flex-grow">{renderSidebar()}</div> */}
                      {/* </main>   */}
                    {/* </Elements>  */}
              {/* } */}

              <Elements stripe={stripePromise}>
                {/* <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row"> */}
                <main className="container mt-11 mb-24 lg:mb-32 flex justify-center lg:flex-row">
                  <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
                    {renderGuestMain()}
                  </div>
                  {/* <div className="hidden lg:block flex-grow">{renderSidebar()}</div> */}
                </main>  
              </Elements> 
            </>
          )
        }

        {
          paymentSent === true && (
            <OneButtonCommonLayout backtHref="/">
              <h2 
                className="text-3xl lg:text-4xl font-semibold"
              >
                {"Thank you for your order"}
              </h2>

              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

              <h3 className="text-2xl font-semibold">
                {"We will email you once the order is ready for pickup."}
              </h3>
            </OneButtonCommonLayout>
          )
        }

        {
          paymentSent === false && (
            <OneButtonCommonLayout backtHref="/">
              <h2 
                className="text-3xl lg:text-4xl font-semibold"
              >
                {"Payment was not successful"}
              </h2>

              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

              <h3 className="text-2xl font-semibold">
                {"There seems to be an error, please contact us."}
              </h3>
            </OneButtonCommonLayout>
          )
        }
      </>
    </div>
  );
};

export default CheckOutPage;